export default Object.freeze({
  ANALYTICS_URL: process.env.VUE_APP_ANALYTICS_URL,
  API_VERSION: '/v2.0',
  APP_NAME: 'Actirise platform',
  BOUNCE_PATH: 'Bounce-Path',
  CORPORATE_WEBSITE_URL: 'https://corporate.actirise.com/',
  CROSS_JWT: 'cross-jwt',
  DARK_LAYOUT: 'dark',
  DEFAULT_LANGUAGE: 'en',
  DOC: process.env.VUE_APP_DOCUMENTATION_URL,
  ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT,
  JWT_EXPIRED_MESSAGE: 'Expired JWT Token',
  LAYOUT_FORM_PAGE: 'form-page',
  LAYOUT_FULL: 'full',
  LAYOUT_HORIZONTAL: 'horizontal',
  LIGHT_LAYOUT: 'light',
  LOCALES: ['en', 'fr'],
  PRIVACY_POLICY_URL: 'https://corporate.sparteo.com/privacy-policy',
  ROLES: {
    adminPublisher: 'admin_publisher',
    superAdmin: 'super_administrator'
  },
  ROLES_CONTRACT: ['organization_manager', 'customer'],
  SKIN_STORAGE_KEY: 'vuexy-skin',
  STORAGE_I18N: 'i18n',
  STORAGE_NAME: 'actirise-store',
  SUPPORT_EMAIL: 'support@actirise.com',
  TERMS_OF_SERVICE_URL: 'https://corporate.actirise.com/terms',
  TOKEN_TYPE: 'Bearer'
})
